import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Box, Grid, GridItem, Heading, Text, HStack } from "@chakra-ui/react";
import Layout from "../components/Layout";
import Seo from "../components/seo";
import Tech from "../components/Tech";
import ContactForm from "../components/ContactForm";
import Container from "../components/ui/Container";
import AttributesBox from "../components/AttributesBox";
import SystemBox from "../components/SystemBox";

function ResponsiveWebApp() {
  return (
    <Layout>
      <Seo
        title="BITDECODERS | PROGRESSIVE WEB APP DEVELOPMENT COMPANY INDIA"
        meta={[
          {
            name: "keywords",
            content:
              "website development company, website developer near me, website designing company, ecommerce website developer, web developer companies, indian web design company, website design and development company, web development agency, best web development company, web design and development services, ecommerce website development company, web development company near me, ecommerce web development company, ecommerce web design company, custom web design services, website development company near me, web design and development company, best web designer, top website development company, custom web development company, best website developers, web design & development company, website development agency, webdesign company, best ecommerce website development company, business website development, web and mobile app development companies, web design & development services, ecommerce website designing company, top web design company, website design & development company, mobile app designing company, best web designing companies, best indian web design company, web development companies services",
          },
          {
            name: "description",
            content:
              "We specialize in providing website and progressive web app designing and development services including solution integration and offshore IT outsourcing to small and medium-sized businesses (SMB) across the globe. Go with us and you can benefit from our experience, with an amazing range of skills, deep vertical industries expertise, and excellence in top technologies, like Html, Html5, Node JS, React JS, Next JS, Gatsby, Swift, Kotlin, Flutter, Data Base Management, and various other custom application fields.",
          },
        ]}
      />

      <Box position="relative">
        <StaticImage
          src="../images/third-party-header.png"
          alt="Bitdecoders responsive banner image"
          layout="fullWidth"
        />

        <Heading
          as="h1"
          fontSize={{ base: "lg", md: "3xl", lg: "5xl" }}
          fontWeight="bold"
          p={{ base: "4", md: "6", lg: "8" }}
          position="absolute"
          left="0"
          bottom={{ base: "0.5", md: "4", lg: "10" }}
        >
          <HStack>
            <Text color="red.500">PROGRESSIVE</Text>
            <Text color="white">WEB APP</Text>
          </HStack>
          <Box fontSize={{ base: "xs", md: "sm", lg: "md" }} fontWeight="bold">
            <Text color="white">
              Let&apos;s talk about what we can build together
            </Text>
          </Box>
        </Heading>
      </Box>

      <Container fluid>
        <div>
          <div className="w-full relative" />
          <div className="grid grid-cols-1 md:grid-cols-2">
            <div className="leading-7  ml-10 p-2 text-lg pt-8">
              <p>Your website is the first impression you make to potential</p>
              <p>
                clients. You want a website that will represent your company
              </p>
              <p>within seconds of people viewing it. Now, more than ever</p>
              <p>before it is imperative to select a web design company, who</p>
              <p>can deliver you a website that will improve your online</p>
              <p>presence.</p>
              <p className="p-2">
                <p>We specialize in providing web designing and development</p>
                <p>services including solution integration and offshore IT</p>
                <p>
                  outsourcing to small and medium-sized businesses{" "}
                  <span className="font-bold">(SMB)</span>
                </p>
                <p>across the globe. Go with us and you can benefit from our</p>
                <p>
                  experience, with an amazing range of skills, deep vertical
                </p>
                <p>
                  industries expertise, and excellence in top technologies, like
                </p>
                <p>
                  <span className="font-bold">
                    Html, Html5, Node JS, React JS, Next JS, Gatsby, Swift,
                    Kotlin,
                  </span>
                </p>
                <p>
                  <span className="font-bold">
                    Flutter, Data Base Management,
                  </span>
                  and various other custom
                </p>
                application fields.
              </p>
              <p className="p-2">
                <p>
                  To make your business reach out to numerous users of mobile
                </p>
                <p>
                  devices, we design websites that are compatible with all kinds
                </p>
                <p>
                  of mobile devices- Smartphones, iPhones, iPads, and Tablets.
                </p>
                <p>
                  Regardless of the kind of device, the impact of the
                  presentation
                </p>
                <p>
                  of the website remains unchanged because of the same quality
                </p>
                <p>
                  of viewing experience that you would expect on a PC or laptop.
                </p>
              </p>
            </div>
            <div className="relative md:w-3/4 md:-top-20 ">
              <ContactForm />
            </div>
          </div>
          <Box>
            <Heading
              as="h2"
              textAlign="center"
              fontWeight="medium"
              fontSize="4xl"
              textTransform="uppercase"
              mb="6"
            >
              <span className="text-red-500">
                PROGRESSIVE WEB APP DEVELOPMENT
              </span>{" "}
              ATTRIBUTES
            </Heading>

            <Grid
              my="4"
              templateColumns={{
                base: "repeat(2, 1fr)",
                md: "repeat(2, 1fr)",
                xl: "repeat(4, 1fr)",
              }}
              templateRows={{
                base: "repeat(2, 1fr)",
                md: "repeat(2, 1fr)",
                xl: "repeat(1, 1fr)",
              }}
              gap="6"
            >
              <GridItem>
                <AttributesBox description="Mobile Fit Designs Latest Architecture" />
              </GridItem>

              <GridItem>
                <AttributesBox description={`Swiftly &amp; Smoother progres`} />
              </GridItem>

              <GridItem>
                <AttributesBox description="Latest Technologies Contact Form Setup Live Chat Setup" />
              </GridItem>

              <GridItem>
                <AttributesBox description="Whatsapp Chat Setup Service & Support More" />
              </GridItem>
            </Grid>
          </Box>

          <Box>
            <Heading
              as="h2"
              textAlign="center"
              fontWeight="medium"
              fontSize="4xl"
              textTransform="uppercase"
              mb="6"
            >
              <span className="text-red-500">
                PROGRESSIVE WEB APP DEVELOPMENT
              </span>{" "}
              PROCESS
            </Heading>

            <Grid
              my="6"
              templateColumns={{
                base: "repeat(2, 1fr)",
                md: "repeat(3, 1fr)",
                xl: "repeat(6, 1fr)",
              }}
              templateRows={{
                base: "repeat(3, 1fr)",
                md: "repeat(2, 1fr)",
                xl: "repeat(1, 1fr)",
              }}
              gap="6"
            >
              <GridItem>
                <SystemBox description={`Planning &amp; Wireframing`} />
              </GridItem>

              <GridItem>
                <SystemBox description="Flow Structure" />
              </GridItem>

              <GridItem>
                <SystemBox description={`Designing &amp; Development`} />
              </GridItem>

              <GridItem>
                <SystemBox description="Feedback" />
              </GridItem>

              <GridItem>
                <SystemBox description="Testing" />
              </GridItem>

              <GridItem>
                <SystemBox description="Delivery" />
              </GridItem>
            </Grid>
          </Box>

          <div className="my-8">
            <Tech />
          </div>
        </div>
      </Container>
    </Layout>
  );
}

export default ResponsiveWebApp;
